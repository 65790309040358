export const licenseTable = {
  entityName: "Key",
  entityNamePlural: "Keys",
  apiPath: "admin/licenses",
  routerNamespace: "keys",
  initialFilters: {
    o: '-id',
    page: 1,
    q: '',
    page_size: 50
  },
  headers: [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
    },
    {
      text: 'Community',
      align: 'start',
      value: 'client.name',
    },
    {
      text: 'Group',
      align: 'start',
      value: 'group.name',
    },
    {
      text: 'Key',
      align: 'start',
      value: 'license_id',
    },
    {
      text: 'Valid',
      align: 'start',
      value: 'valid',
    },
    {
      text: 'Creation date',
      align: 'start',
      value: 'added_on',
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false 
    },
  ],
  item: {
    valid: true,
    group: null,
  },
  itemTemplate: {
    valid: true,
    group: null,
  },
  canEdit: () => { return true },
  canDestroy: () => { return false },
}

export const invoiceTable = {
  entityName: "Invoice",
  entityNamePlural: "Invoices",
  apiPath: "admin/invoices",
  routerNamespace: "invoices",
  initialFilters: {
    o: '-public_id',
    page: 1,
    q: '',
    page_size: 50
  },
  headers: [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
    },
    {
      text: 'Reference',
      align: 'start',
      value: 'public_id',
    },
    {
      text: 'Community',
      align: 'start',
      value: 'client.name',
    },
    {
      text: 'Date',
      align: 'start',
      value: 'date',
    },
    {
      text: 'Draft',
      align: 'start',
      value: 'is_draft',
    },
    {
      text: 'Gross amount',
      align: 'start',
      value: 'excluding_tax_amount',
    },
    {
      text: 'Currency',
      align: 'start',
      value: 'currency',
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false 
    },
  ],
  item: {
    is_draft: true,
    date: null,
    free_rows: [],
    usage_rows: [],
    currency: "EUR",
    notes: "",
    tax_amount: 0,
    excluding_tax_amount: 0,
  },
  itemTemplate: {
    is_draft: true,
    date: null,
    free_rows: [],
    usage_rows: [],
    notes: "",
    currency: "EUR",
    tax_amount: 0,
    excluding_tax_amount: 0,
  },
  canEdit: () => { return true },
  canSave: (i) => {return i.is_draft},
  canDestroy: (i) => {return i.is_draft},
}


export const pricingTable = {
  entityName: "Pricing",
  entityNamePlural: "Pricings",
  apiPath: "admin/pricings",
  routerNamespace: "pricings",
  initialFilters: {
    o: '-id',
    page: 1,
    q: '',
    page_size: 50
  },
  headers: [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
    },
    {
      text: 'Code',
      align: 'start',
      value: 'code',
    },
    {
      text: 'Transformation',
      align: 'start',
      value: 'transformation.name',
    },
    {
      text: 'VAT rate',
      align: 'start',
      value: 'vat_rate',
    },
    {
      text: 'Gross price',
      align: 'start',
      value: 'excluding_tax_price',
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false 
    },
  ],
  item: {
    client: null,
    transformation: null,
    code: "transformed_minute",
    vat_rate: "0.2",
    excluding_tax_price: "1",
  },
  itemTemplate: {
    client: null,
    transformation: null,
    code: "transformed_minute",
    vat_rate: "0.2",
    excluding_tax_price: "1",
  },
  canEdit: () => { return true },
  canSave: () => {return true},
  canDestroy: () => {return true},
}

export const labelTable = {
  entityName: "Label",
  entityNamePlural: "Labels",
  apiPath: "admin/labels",
  routerNamespace: "labels",

  initialFilters: {
    o: '-id',
    page: 1,
    q: '',
    page_size: 50
  },
  headers: [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
    },
    {
      text: 'Name',
      align: 'start',
      value: 'name',
    },
    {
      text: 'Community',
      align: 'start',
      value: 'client.name',
    },
    {
      text: 'Users',
      align: 'start',
      value: 'users_count',
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false 
    },
  ],
  item: {
    name: '',
    group: null,
  },
  itemTemplate: {
    name: '',
    group: null,
  },
  canEdit: () => { return true },
  canDestroy: () => { return false },
}

export const abtestTable = {
  entityName: "A/B Test",
  entityNamePlural: "A/B Tests",
  apiPath: "admin/abtests",
  routerNamespace: "abtests",

  initialFilters: {
    o: '-id',
    page: 1,
    q: '',
    page_size: 50
  },
  headers: [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
    },
    {
      text: 'Name',
      align: 'start',
      value: 'name',
    },
    {
      text: 'Community',
      align: 'start',
      value: 'client.name',
    },
    {
      text: 'Enabled',
      align: 'start',
      value: 'enabled',
    },
    // DISABLED
    // {
    //   text: 'Auto billing',
    //   align: 'start',
    //   value: 'auto_billing',
    // },
    {
      text: 'Start',
      align: 'start',
      value: 'start',
    },
    {
      text: 'End',
      align: 'start',
      value: 'end',
    },
    {
      text: 'Users',
      align: 'start',
      value: 'users_count',
    },
    {
      text: 'Progress',
      align: 'start',
      value: 'goal_progress.total',
      sortable: false,
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false 
    },
  ],
  item: {
    name: '',
    worktime_start: null,
    worktime_end: null,
    start: null,
    end: null,
    timezone: 'Europe/Paris',
    report_emails: [],
    firstday_all_off: true,
    enabled: true,
    needs_tuning: false,
    auto_billing: false,
    connected_app: null,
    vmocall_version: null,
    goal_agents: null,
    goal_hours: null,
    goal_progress: {},
    transformations: [
      {transformation: null, weight: null, params: null},
      {transformation: null, weight: null, params: null},
    ],
    kpis: [],
    internal_comment: null,
    group: null,
  },
  itemTemplate: {
    name: '',
    worktime_start: null,
    worktime_end: null,
    start: null,
    end: null,
    timezone: 'Europe/Paris',
    report_emails: [],
    firstday_all_off: true,
    enabled: true,
    needs_tuning: false,
    auto_billing: false,
    connected_app: null,
    vmocall_version: null,
    goal_agents: null,
    goal_hours: null,
    goal_progress: {},
    transformations: [
      {transformation: null, weight: null, params: null},
      {transformation: null, weight: null, params: null},
    ],
    kpis: [],
    internal_comment: null,
    group: null,
  },
  canEdit: () => { return true },
  canDestroy: () => { return false },
}


export const machineTable = {
  entityName: "Machine",
  entityNamePlural: "Machines",
  apiPath: "admin/machines",
  routerNamespace: "machines",

  initialFilters: {
    o: '-last_update',
    page: 1,
    q: '',
    page_size: 50
  },
  headers: [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
    },
    {
      text: 'Name',
      align: 'start',
      value: 'name',
    },
    {
      text: 'Community',
      align: 'start',
      value: 'client.name',
    },
    {
      text: 'IP',
      align: 'start',
      value: 'ip.public',
    },
    {
      text: 'Country',
      align: 'start',
      value: 'ip.country_code',
    },
    {
      text: 'Last seen',
      align: 'start',
      value: 'last_update',
    },
    {
      text: 'App',
      align: 'start',
      value: 'app',
    },
    {
      text: 'Connected app',
      align: 'start',
      value: 'stream.connected_app',
      sortable: false,
    },
    {
      text: 'Users',
      align: 'start',
      value: 'users.length',
      sortable: false,
    },
    {
      text: 'Issues',
      align: 'start',
      value: 'issues.length',
      sortable: false,
    }
  ],
  item: {},
  itemTemplate: {},
  canEdit: () => { return false },
  canDestroy: () => { return false },
}


export const recordingTable = {
  entityName: "Recording",
  entityNamePlural: "Recordings",
  apiPath: "admin/recordings",
  routerNamespace: "recordings",

  initialFilters: {
    o: '-creation_date',
    page: 1,
    q: '',
    page_size: 50
  },
  headers: [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
    },
    {
      text: 'Community',
      align: 'start',
      value: 'user.client.name',
    },
    {
      text: 'User',
      align: 'start',
      value: 'user.id',
    },
    {
      text: 'Transformation',
      align: 'start',
      value: 'transformation.name',
    },
    {
      text: 'Mode',
      align: 'start',
      value: 'params.mode',
    },
    {
      text: 'date',
      align: 'start',
      value: 'creation_date',
    },
    {
      text: 'Duration',
      align: 'start',
      value: 'duration',
    },
    {
      text: 'Size',
      align: 'start',
      value: 'bytes',
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false 
    },
  ],
  item: {},
  itemTemplate: {},
  canEdit: () => { return false },
  canDestroy: () => { return false },
}

export const accountTable = {
  entityName: "Account",
  entityNamePlural: "Accounts",
  apiPath: "admin/accounts",
  routerNamespace: "accounts",
  itemIdField: "email",
  initialFilters: {
    q: '',
  },
  headers: [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
      sortable: false,
    },
    {
      text: 'Email',
      align: 'start',
      value: 'email',
      sortable: false,
    },
    {
      text: 'Name',
      align: 'start',
      value: 'name',
      sortable: false,
    },
    {
      text: 'Creation date',
      align: 'start',
      value: 'joined_date',
      sortable: false,
    },
    {
      text: 'Permissions',
      align: 'start',
      value: 'roles',
      sortable: false,
    },
    {
      text: 'Email verified',
      align: 'start',
      value: 'email_verified',
      sortable: false,
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false 
    },
  ],
  item: {
    first_name: "",
    last_name: "",
    roles: [],
    group: null,
    email: null,
    email_verified: false,
    joined_date: "",
    password: "",
  },
  itemTemplate: {
    roles: [],
    group: null,
    email: null,
    email_verified: false,
    joined_date: "",
    password: "",
  },
  canEdit: () => { return true },
  canDestroy: () => { return false },
}


export const groupTable = {
  entityName: "Group",
  entityNamePlural: "Groups",
  apiPath: "admin/groups",
  routerNamespace: "groups",

  initialFilters: {
    o: '-id',
    page: 1,
    q: '',
    page_size: 50
  },
  headers: [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
    },
    {
      text: 'Name',
      align: 'start',
      value: 'name',
    },
    {
      text: 'Community',
      align: 'start',
      value: 'client.name',
    },
    {
      text: 'Creation date',
      align: 'start',
      value: 'joined_date',
      sortable: false,
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false 
    },
  ],
  item: {
    name: '',
  },
  itemTemplate: {
    name: '',
  },
  canEdit: () => { return true },
  canDestroy: () => { return false },
}


export const clientTable = {
  entityName: "Community",
  entityNamePlural: "Communities",
  apiPath: "admin/clients",
  routerNamespace: "communities",

  initialFilters: {
    o: '-id',
    page: 1,
    q: '',
    page_size: 50
  },
  headers: [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
    },
    {
      text: 'Name',
      align: 'start',
      value: 'name',
    },
    {
      text: 'Contact email',
      align: 'start',
      value: 'email',
      sortable: false
    },
    {
      text: 'Users',
      align: 'start',
      value: 'users_count',
    },
    {
      text: 'Machines',
      align: 'start',
      value: 'machines_count',
    },
    {
      text: 'Creation date',
      align: 'start',
      value: 'creation_date',
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false 
    },
  ],
  replaceEmptyWithNull: ["email_billing"],
  item: {
    name: '',
    email: "",
    email_billing: "",
    config: {},
    address: {
      name: "",
      locality: "",
      postal_code: "",
      address: "",
      country: {code: ""},
    },
    vat_number: null,
  },
  itemTemplate: {
    name: '',
    email: "",
    config: {},
    address: {
      name: "",
      locality: "",
      postal_code: "",
      address: "",
      country: {code: ""},
    },
    vat_number: null,
  },
  prepareItem (i) {
    i.vat_number = i.vat_number || null
    i.address = i.address || {
      name: i.name,
      locality: "",
      postal_code: "",
      address: "",
      country: {code: ""},
    }
  },
  canEdit: () => { return true },
  canDestroy: () => { return false },
  canCreate: true,
}